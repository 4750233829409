export const parseCookie = (key: string) => {
  const cookie = document.cookie
    .split(';')
    .map((v) => v.split('='))
    .find((values) => values[0].trim() === key)
  return cookie ? cookie[1].trim() : null
}

const clearCookie = () => {
  const cookies = document.cookie.split(';')
  for (let index = 0; index < cookies.length; index++) {
    const cookie = cookies[index]
    const nameAndValue = cookie.split('=')
    if (nameAndValue && nameAndValue.length === 2) {
      document.cookie = `${nameAndValue[0]}=${nameAndValue[1]};path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`
    }
  }
}

export const hasUserToken = () => {
  return getUserToken() !== null
}

export const getUserToken = () => {
  return parseCookie('TOKEN')
}

export const clearUserToken = () => {
  clearCookie()
}
