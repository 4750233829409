import axios from 'axios'
import Page from '../../common/domain/Page'
import PageRequest from '../../common/domain/PageRequest'

import Material from '../domain/Material'
import MaterialDetail from '../domain/MaterialDetail'
import Memo from '../domain/Memo'
import Attachment from '../domain/Attachment'

export const getMaterials = async (
  topicId: string,
  keyword: string,
  deepSearch: boolean,
  pageRequest: PageRequest
): Promise<Page<Material>> => {
  const { data } = await axios.get(`/app/api/materials/topic/${topicId}`, {
    params: { ...pageRequest, keyword, deepSearch },
  })
  return data
}

export const addMaterials = async (
  projectId: number,
  topicId: string,
  articleIds: number[]
): Promise<void> => {
  await axios.post(`/app/api/materials/project/${projectId}/topic/${topicId}`, articleIds, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const deleteMaterials = async (materialIds: number[]): Promise<void> => {
  await axios.delete(`/app/api/materials`, {
    params: {
      ids: materialIds.join(','),
    },
  })
}

export const moveMaterialsToOtherTopic = async (
  topicId: string,
  materialIds: number[]
): Promise<void> => {
  await axios.put(`/app/api/materials/topic/${topicId}`, materialIds, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const getMaterialDetail = async (id: number): Promise<MaterialDetail> => {
  const { data } = await axios.get(`/app/api/materials/${id}`)
  return data
}

export const addMaterialDetail = async (
  projectId: number,
  topicId: string,
  requestBody: MaterialDetail
): Promise<void> => {
  await axios.post(`/app/api/materials/project/${projectId}/topic/${topicId}/external`, requestBody)
}

export const updateMaterialDetail = async (requestBody: MaterialDetail): Promise<void> => {
  await axios.put(`/app/api/materials/${requestBody.id}`, requestBody)
}

export const getMemo = async (materialId: number): Promise<Memo> => {
  const { data } = await axios.get(`/app/api/materials/${materialId}/memo`)
  return data
}

export const saveMemo = async (materialId: number, requestBody: Memo): Promise<void> => {
  await axios.post(`/app/api/materials/${materialId}/memo`, requestBody)
}

export const getAttachment = async (materialId: number): Promise<Attachment> => {
  const { data } = await axios.get(`/app/api/materials/${materialId}/attachment`)
  return data
}

export const saveAttachment = async (materialId: number, file: File): Promise<void> => {
  const formData = new FormData()
  formData.append('file', file)
  await axios.post(`/app/api/materials/${materialId}/attachment`, formData, {
    headers: {
      'Content-Type': 'x-www-form-urlencoded',
    },
  })
}

export const deleteAttachment = async (id: number): Promise<void> => {
  await axios.delete(`/app/api/materials/attachment/${id}`)
}

export const getMaterialsWithMemo = async (
  projectId: number,
  keyword: string,
  pageRequest: PageRequest
): Promise<Page<Material>> => {
  const { data } = await axios.get(`/app/api/materials/project/${projectId}`, {
    params: {
      ...pageRequest,
      keyword,
    },
  })
  return data
}
