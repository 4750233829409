import React from 'react'

import InitialWorkspace from './InitialWorkspace'
import RecentWorkspace from './RecentWorkspace'
import RecentWorkspaceItems from '../domain/RecentWorkspaceItems'

type Props = {
  recentWorkspace: RecentWorkspaceItems
  openModal: () => void
}

const DefaultWorkspace = ({ recentWorkspace, openModal }: Props) => {
  return (
    <>
      {recentWorkspace.projects.length === 0 ? (
        <InitialWorkspace openModal={openModal} />
      ) : (
        <RecentWorkspace workspace={recentWorkspace} />
      )}
    </>
  )
}

export default DefaultWorkspace
