import React, { useCallback, useContext, useEffect, useState } from 'react'

import CancelButton from '../../common/component/CancelButton'
import ConfirmButton from '../../common/component/ConfirmButton'

import MaterialDetail from '../domain/MaterialDetail'
import { getMaterialDetail, updateMaterialDetail } from '../repository/MaterialsRepository'
import { Context } from '../provider/TactoProjectProvider'
import MaterialDetailsSection from './MaterialDetailsSection'

type Props = {
  id: number
  closeModal: () => void
}

const initialMaterial: MaterialDetail = {
  abstractsContent: '',
  authors: '',
  doi: '',
  id: 0,
  journal: '',
  journalType: '',
  memoContent: '',
  memoId: 0,
  memoTitle: '',
  publishYear: '',
  publisher: '',
  title: '',
}

const MaterialDetails = ({ id, closeModal }: Props) => {
  const { refreshItems } = useContext(Context)!
  const [materialDetail, setMaterialDetail] = useState<MaterialDetail>(initialMaterial)

  useEffect(() => {
    getMaterialDetail(id).then((detail) => setMaterialDetail(detail))
  }, [id])

  const onSave = useCallback(() => {
    updateMaterialDetail(materialDetail).then(refreshItems).finally(closeModal)
  }, [materialDetail, refreshItems, closeModal])

  const onChangeMaterialDetail = useCallback(
    (key: string, value: string) => {
      setMaterialDetail({ ...materialDetail, [key]: value })
    },
    [materialDetail]
  )

  return (
    <div className="material-details-modal">
      <div className="title">상세 정보</div>
      <MaterialDetailsSection
        materialDetail={materialDetail}
        onChangeMaterialDetail={onChangeMaterialDetail}
      />
      <div className="buttons">
        <CancelButton name="취소" onClick={closeModal} />
        <ConfirmButton name="저장" onClick={onSave} />
      </div>
    </div>
  )
}

export default MaterialDetails
