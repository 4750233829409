import TopicTreeNode from '../domain/TopicTreeNode'

export function searchSelectedTopic(
  selectedId: string,
  current: TopicTreeNode
): TopicTreeNode | null {
  if (current.id === selectedId) {
    return current
  }
  if (!current.children) {
    return null
  }
  const currentChild = current.children[selectedId]
  if (currentChild) {
    return currentChild
  }
  for (const value of Object.values(current.children)) {
    const result = searchSelectedTopic(selectedId, value)
    if (result) {
      return result
    }
  }
  return null
}
