type Props = {
  keyword: string
  setKeyword: (value: string) => void
  search: () => void
  openModal: () => void
}

const WorkspaceTopSection = ({ keyword, setKeyword, search, openModal }: Props) => {
  return (
    <div className="top-section" aria-label="workspace-top-section">
      <div className="search">
        <input
          type="text"
          placeholder="검색어를 입력해주세요"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <button aria-label="search" onClick={search} />
      </div>
      <button className="project-creation" onClick={() => openModal()}>
        새 프로젝트
      </button>
    </div>
  )
}

export default WorkspaceTopSection
