import TopicTreeNode from '../domain/TopicTreeNode'
import { RightSection } from './TopicTreeManagement'
import { Fragment, ReactNode } from 'react'

type Props = {
  rootNode?: TopicTreeNode | null
  selectedId: string
  onSelectTopic: (id: string) => void
  onSetCurrentRightSection: (rightSection: RightSection) => void
}

function drawTreeAside(
  treeNodeMap: { [key in string]: TopicTreeNode },
  selectedId: string,
  onSelectTopic: (id: string) => void,
  onSetCurrentRightSection: (rightSection: RightSection) => void
): ReactNode {
  return Object.values(treeNodeMap).map((value) => {
    return (
      <Fragment key={value.id}>
        <div
          className={`tree-node-item children ${selectedId === value.id && 'on'}`}
          onClick={() => onSelectTopic(value.id)}
        >
          <li>{value.name}</li>
          {selectedId === value.id && (
            <div className="tree-item-buttons">
              <button
                className="icon edit"
                aria-label="edit-button"
                onClick={(event) => {
                  event.stopPropagation()
                  onSetCurrentRightSection('modify')
                }}
              />
              <button
                className="icon delete"
                aria-label="delete-button"
                onClick={(event) => {
                  event.stopPropagation()
                  onSetCurrentRightSection('delete')
                }}
              />
              <button
                className="icon add"
                aria-label="add-button"
                onClick={(event) => {
                  event.stopPropagation()
                  onSetCurrentRightSection('create')
                }}
              />
            </div>
          )}
        </div>
        {value.children && (
          <ul className="children">
            {drawTreeAside(value.children, selectedId, onSelectTopic, onSetCurrentRightSection)}
          </ul>
        )}
      </Fragment>
    )
  })
}

const EditableTopicTreeSection = ({
  rootNode,
  selectedId,
  onSelectTopic,
  onSetCurrentRightSection,
}: Props) => {
  return (
    <div aria-label="topic-tree-section" className="editable-topic-tree-section">
      {rootNode?.children ? (
        drawTreeAside(rootNode.children, selectedId, onSelectTopic, onSetCurrentRightSection)
      ) : (
        <div className="empty-info">새로운 토픽을 만들어주세요.</div>
      )}
    </div>
  )
}

export default EditableTopicTreeSection
