import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import WorkspaceTactoProject from '../domain/WorkspaceTactoProject'
import { updatedDate } from '../util/time-formatter'

type Props = {
  projects: WorkspaceTactoProject[]
}

const TactoProjectList = ({ projects }: Props) => {
  const navigate = useNavigate()

  const moveToProjectPage = useCallback(
    (id: number) => {
      navigate(`/tacto/projects/${id}`)
    },
    [navigate]
  )

  return (
    <>
      <div className="workspace-items" aria-label="project-list">
        {projects.map((item) => (
          <div className="workspace-item" key={item.id} onClick={() => moveToProjectPage(item.id)}>
            <div className="name">{item.name}</div>
            <div className="description">{item.description}</div>
            <div className="info-wrap">
              <div className="etc-info">
                <div className="topic-info">
                  <div>{item.topicSummary.mainTopic}</div>
                  <div>{item.topicSummary.topicCount}개의 토픽</div>
                </div>
                <div className="count-info">
                  <div>
                    메모 <span>{item.memoCount}</span>
                  </div>
                  <div className="divider" />
                  <div>
                    아티클 <span>{item.tactoArticleCount}</span>
                  </div>
                  <div className="divider" />
                  <div>
                    자료 <span>{item.materialCount}</span>
                  </div>
                </div>
              </div>
              <div className="updated-at">{updatedDate(item.updatedAt)}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default TactoProjectList
