export const initialSimpleTactoArticle: SimpleTactoArticle = {
  id: 0,
  title: '',
  content: '',
  isCompleted: false,
}

interface SimpleTactoArticle {
  id: number
  title: string
  content: string
  isCompleted: boolean
}

export default SimpleTactoArticle
