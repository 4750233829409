import { useNavigate } from 'react-router-dom'
import React, { useCallback } from 'react'
import WorkspaceTopicTree from '../domain/WorkspaceTopicTree'
import { updatedDate } from '../util/time-formatter'

type Props = {
  topicTrees: WorkspaceTopicTree[]
}

const TopicTreeList = ({ topicTrees }: Props) => {
  const navigate = useNavigate()

  const moveToProjectPage = useCallback(
    (id: number) => {
      navigate(`/tacto/projects/${id}`)
    },
    [navigate]
  )

  return (
    <div className="workspace-items" aria-label="topic-tree-list">
      {topicTrees.map((item, index) => (
        <div
          className="workspace-item"
          key={`${item.projectId}-${index}`}
          onClick={() => moveToProjectPage(item.projectId)}
        >
          <div className="project-name">{item.projectName}</div>
          <div className="name">{item.name}</div>
          <div className="description">{item.description}</div>
          <div className="info-wrap">
            <div className="etc-info">
              <div className="count-info">
                <div>
                  메모 <span>{item.memoCount}</span>
                </div>
                <div className="divider" />
                <div>
                  아티클 <span>{item.tactoArticleCount}</span>
                </div>
                <div className="divider" />
                <div>
                  자료 <span>{item.materialCount}</span>
                </div>
              </div>
            </div>
            <div className="updated-at">{updatedDate(item.updatedAt)}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default TopicTreeList
