import React, { useCallback, useEffect, useState } from 'react'

import AllCheckBox from '../../common/component/AllCheckBox'
import CheckBox from '../../common/component/CheckBox'
import Pagination from '../../common/component/Pagination'
import { usePageable } from '../../common/hooks/usePageable'
import { useCheckbox } from '../../common/hooks/useCheckbox'
import { updatedDate } from '../util/time-formatter'

import { getTactoArticles } from '../repository/TactoArticleRepository'
import TactoArticle from '../domain/TactoArticle'
import TactoProjectDetail from '../domain/TactoProjectDetail'
import ConfirmButton from '../../common/component/ConfirmButton'
import { useNavigate } from 'react-router-dom'

type Props = {
  projectDetail: TactoProjectDetail
}

const TactoArticlesSection = ({ projectDetail }: Props) => {
  const navigate = useNavigate()
  const [keyword, setKeyword] = useState<string>('')
  const [tactoArticles, setTactoArticles] = useState<TactoArticle[]>([])

  const { pageInfo, setupPage } = usePageable()
  const { isAllChecked, checkedItems, onAllCheckHandler, onCheckedHandler, clearAllChecked } =
    useCheckbox()

  const moveToPage = useCallback(
    (currentPage: number) => {
      if (projectDetail) {
        clearAllChecked()
        getTactoArticles(projectDetail.id, keyword, { page: currentPage, size: 10 }).then(
          (response) => {
            setupPage(response, currentPage)
            setTactoArticles(response.content)
          }
        )
      }
    },
    [projectDetail, clearAllChecked, keyword, setupPage]
  )

  const onSearchHandler = useCallback(() => moveToPage(0), [moveToPage])

  useEffect(() => {
    moveToPage(0)
    // eslint-disable-next-line
  }, [projectDetail])

  return (
    <>
      <div className="tacto-project">
        <div className="topic-info-section">
          <div aria-label="section-title" className="title">
            아티클
          </div>
        </div>
        <div className="topic-top-section">
          <div className="buttons">
            <AllCheckBox
              isAllChecked={isAllChecked}
              onAllCheckHandler={(e) => onAllCheckHandler(e, tactoArticles)}
            />
            <div className="button">
              <div className="icon topic-export-button" />
              <button>내보내기</button>
            </div>
            <div className="button" onClick={() => {}}>
              <div className="icon topic-delete-button" />
              <button>삭제</button>
            </div>
          </div>
          <div className="right-container">
            <div className="search">
              <input
                type="text"
                placeholder="검색어를 입력해주세요"
                onChange={(e) => setKeyword(e.target.value)}
              />
              <button aria-label="search" onClick={onSearchHandler} />
            </div>
            <ConfirmButton
              name="새 아티클"
              isFitContent={true}
              onClick={() => navigate(`/tacto/projects/${projectDetail.id}/articles`)}
            />
          </div>
        </div>
        <div className="project-item-list">
          {tactoArticles.map((tactoArticle) => (
            <div key={tactoArticle.id} className="project-item-wrap">
              <CheckBox
                onChange={(e) => onCheckedHandler(e, tactoArticle.id)}
                className="sgl-check unit"
                ariaLabel={`checkbox-${tactoArticle.id}`}
                isAllChecked={isAllChecked}
                checked={checkedItems.has(tactoArticle.id)}
              />
              <div
                className="tactoArticle"
                onClick={() =>
                  navigate(`/tacto/projects/${projectDetail.id}/articles/${tactoArticle.id}`)
                }
              >
                <div className="title">{tactoArticle.title}</div>
                <div className="content">{tactoArticle.content}</div>
                <div className="progress-container">
                  <div className={`progress-badge ${tactoArticle.isCompleted ? 'completed' : ''}`}>
                    <span>{tactoArticle.contentSize} 자</span>
                    <span className="dot" />
                    <span>{tactoArticle.isCompleted ? '완료' : '작성중'}</span>
                  </div>
                  <div className="updated-at">{updatedDate(tactoArticle.updatedAt)}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Pagination moveToPage={moveToPage} pageInfo={pageInfo} />
    </>
  )
}

export default TactoArticlesSection
