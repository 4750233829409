import React, {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import Location from '../../common/component/Location'
import { getProject } from '../repository/TactoProjectRepository'
import TactoProjectDetail, { initialTactoProjectDetail } from '../domain/TactoProjectDetail'
import TopicTreeSection from '../component/TopicTreeSection'
import TopicTreeNode from '../domain/TopicTreeNode'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getMaterialDetail,
  getMaterials,
  updateMaterialDetail,
} from '../repository/MaterialsRepository'
import Material from '../domain/Material'
import { usePageable } from '../../common/hooks/usePageable'
import Pagination from '../../common/component/Pagination'
import { updatedDate } from '../util/time-formatter'
import { searchSelectedTopic } from '../util/topic-tree'
import MaterialDetail from '../domain/MaterialDetail'
import MaterialDetailsSection from '../component/MaterialDetailsSection'
import CancelButton from '../../common/component/CancelButton'
import ConfirmButton from '../../common/component/ConfirmButton'
import NiceSelect from '../../common/component/NiceSelect'
import simpleTactoArticle, { initialSimpleTactoArticle } from '../domain/SimpleTactoArticle'
import { getTactoArticle, saveTactoArticle } from '../repository/TactoArticleRepository'

const TactoArticle = () => {
  const { projectId, articleId } = useParams()
  const navigate = useNavigate()
  const [keyword, setKeyword] = useState<string>('')
  const [materials, setMaterials] = useState<Material[]>([])
  const [materialDetail, setMaterialDetail] = useState<MaterialDetail | null>(null)
  const [projectDetail, setProjectDetail] = useState<TactoProjectDetail>(initialTactoProjectDetail)
  const [selectedTopic, setSelectedTopic] = useState<TopicTreeNode | null>(null)
  const [tactoArticle, setTactoArticle] = useState<simpleTactoArticle>(initialSimpleTactoArticle)

  const currentWidth = useRef<number>(400)
  const startWidth = useRef<number>(0)
  const leftSectionRef = useRef<HTMLDivElement>(null)
  const { pageInfo, setupPage } = usePageable()

  const resize = useCallback((evt: MouseEvent) => {
    const dx = evt.screenX - startWidth.current
    startWidth.current = evt.screenX
    const width = currentWidth.current + dx
    if (width <= 820 && width >= 340 && leftSectionRef.current) {
      currentWidth.current = width
      leftSectionRef.current.style.width = `${width}px`
      leftSectionRef.current.style.minWidth = `${width}px`
    }
  }, [])

  const draggableResize = useCallback(
    (e: ReactMouseEvent) => {
      startWidth.current = e.screenX
      const releaseResize = () => {
        document.removeEventListener('mousemove', resize)
        document.removeEventListener('mouseup', releaseResize)
      }
      document.addEventListener('mousemove', resize)
      document.addEventListener('mouseup', releaseResize)
    },
    [resize]
  )

  useEffect(() => {
    if (projectId) {
      getProject(+projectId).then((response) => {
        setProjectDetail(response)
        if (response.rootNode?.children) {
          const firstChild = Object.values(response.rootNode.children)[0]
          setSelectedTopic(firstChild)
        }
      })
    }
  }, [projectId])

  const moveToPage = useCallback(
    (currentPage: number) => {
      if (selectedTopic) {
        getMaterials(selectedTopic.id, keyword, false, { page: currentPage, size: 10 }).then(
          (response) => {
            setupPage(response, currentPage)
            setMaterials(response.content)
          }
        )
      }
    },
    [selectedTopic, keyword, setupPage]
  )

  const onSelectTopic = useCallback(
    (id: string) => {
      if (projectDetail.rootNode) {
        const foundChildTopic = searchSelectedTopic(id, projectDetail.rootNode)
        setSelectedTopic(foundChildTopic)
      }
    },
    [projectDetail.rootNode]
  )

  useEffect(() => {
    moveToPage(0)
    // eslint-disable-next-line
  }, [selectedTopic])

  useEffect(() => {
    if (articleId) {
      getTactoArticle(projectDetail.id, +articleId).then((response) => setTactoArticle(response))
    }
  }, [articleId, projectDetail])

  const onSearchHandler = useCallback(() => moveToPage(0), [moveToPage])

  const fetchMaterialDetail = (materialId: number) => {
    getMaterialDetail(materialId).then((response) => setMaterialDetail(response))
  }

  const onSaveHandler = useCallback(() => {
    saveTactoArticle(projectDetail.id, tactoArticle).then((id) =>
      navigate(`/tacto/projects/${projectDetail.id}/articles/${id}`, { replace: true })
    )
  }, [navigate, projectDetail, tactoArticle])

  const onCompleteHandler = useCallback(() => {
    saveTactoArticle(projectDetail.id, { ...tactoArticle, isCompleted: true }).then((id) => {
      navigate(`/tacto/projects/${projectDetail.id}/articles/${id}`, { replace: true })
    })
  }, [navigate, projectDetail, tactoArticle])

  return (
    <main>
      <div className="container">
        <Location paths={['마이페이지', '워크스페이스', projectDetail.name]} />
        <div className="top-section">
          <div className="back" aria-label="back-button" onClick={() => navigate(-1)} />
          <div className="project-info">
            <h1 className="head">{projectDetail.name}</h1>
            <div className="description">{projectDetail.description}</div>
          </div>
        </div>
        <div className="middle-section">
          <h2>아티클 모드</h2>
          <div onClick={() => navigate(-1)} className="list-button">
            <div className="list-icon" />
            <div>목록으로</div>
          </div>
        </div>
        <div className="content-wrap">
          <div className="left-section" ref={leftSectionRef}>
            <div className="side-topic-tree" aria-label="topic-tree">
              <div className="topic-tree-name">
                <div className="icon tree" />
                <div>{projectDetail.rootNode?.name || projectDetail.name}</div>
              </div>
              <TopicTreeSection
                rootNode={projectDetail?.rootNode}
                selectedId={selectedTopic?.id ?? ''}
                onSelectTopic={onSelectTopic}
              />
            </div>
            <div className="materials" aria-label="materials">
              {materialDetail ? (
                <div aria-label="material-detail" className="material-detail">
                  <div className="title">{materialDetail.title}</div>
                  <MaterialDetailsSection
                    materialDetail={materialDetail}
                    onChangeMaterialDetail={(key, value) =>
                      setMaterialDetail({ ...materialDetail, [key]: value })
                    }
                  />
                  <div className="buttons">
                    <CancelButton name="취소" onClick={() => setMaterialDetail(null)} />
                    <ConfirmButton
                      name="저장"
                      onClick={() => {
                        updateMaterialDetail(materialDetail).then(() => {
                          moveToPage(0)
                          setMaterialDetail(null)
                        })
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="material-list" aria-label="material-list">
                  <div className="search">
                    <input
                      type="text"
                      placeholder="검색어를 입력해주세요"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                    <button aria-label="search" onClick={onSearchHandler} />
                  </div>
                  <div className="project-item-list">
                    {materials.map((material) => (
                      <div key={material.id} className="project-item-wrap">
                        <div className="material" onClick={() => fetchMaterialDetail(material.id)}>
                          <div className="title">{material.title}</div>
                          <div className="content">
                            <div className="left-container">
                              <div>{material.publishYear}</div>
                              <div className="divider" />
                              <div>{material.publisher}</div>
                              <div className="divider" />
                              <div>{material.authors}</div>
                            </div>
                          </div>
                          <div className="attachment-memo-container">
                            <button className={material.attachmentId ? 'existed' : ''}>
                              <div className="icon attachment" />
                              첨부
                            </button>
                            <button className={material.memoId ? 'existed' : ''}>
                              <div className="icon memo" />
                              메모
                            </button>
                            <div className="updated-at">{updatedDate(material.updatedAt)}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Pagination moveToPage={moveToPage} pageInfo={pageInfo} />
                </div>
              )}
            </div>
          </div>
          <div className="divider-line">
            <div className="divider-thumb" onMouseDown={draggableResize} />
          </div>
          <div className="right-section">
            <div aria-label="text-editor" className="text-editor">
              <div className="top-section">
                <div className="section-title">아티클 작성</div>
                <label className="suggestion-toggle">
                  <span>실시간 문장 추천</span>
                  <input role="switch" type="checkbox" aria-label="real-time-suggestion" />
                </label>
              </div>
              <div className="content">
                <div className="label">제목</div>
                <input
                  type="text"
                  aria-label="tacto-article-title"
                  value={tactoArticle.title}
                  onChange={(e) => setTactoArticle({ ...tactoArticle, title: e.target.value })}
                />
              </div>
              <div className="content">
                <div className="label">
                  <div>내용</div>
                  <div>0 자</div>
                </div>
                <textarea
                  aria-label="tacto-article-content"
                  value={tactoArticle.content}
                  onChange={(e) => setTactoArticle({ ...tactoArticle, content: e.target.value })}
                />
              </div>
              <div className="buttons">
                <button className="save" onClick={onSaveHandler}>
                  저장
                </button>
                <button className="done" onClick={onCompleteHandler}>
                  완료
                </button>
              </div>
            </div>
            <div className="suggestions" aria-label="suggestions">
              <div className="top-section">
                <div className="section-title">문장 추천</div>
              </div>
              <div className="select-box-section">
                <div className="select-box-wrap">
                  <div className="label">분야</div>
                  <NiceSelect
                    ariaLabel="areas"
                    options={new Map()}
                    onSelectCallback={() => {}}
                    selectedValue={''}
                  />
                </div>
                <div className="select-box-wrap">
                  <div className="label">단계</div>
                  <NiceSelect
                    ariaLabel="steps"
                    options={new Map()}
                    onSelectCallback={() => {}}
                    selectedValue={''}
                  />
                </div>
                <div className="select-box-wrap">
                  <div className="label">기간</div>
                  <NiceSelect
                    ariaLabel="periods"
                    options={new Map()}
                    onSelectCallback={() => {}}
                    selectedValue={''}
                  />
                </div>
              </div>
              <div className="search">
                <input
                  type="text"
                  placeholder="검색어를 입력해주세요"
                  value={''}
                  onChange={() => {}}
                />
                <button aria-label="search-suggestions" onClick={onSearchHandler} />
              </div>
              <div className="actions-section">
                <div className="action">
                  <div className="icon paste" />
                  붙여넣기
                </div>
                <div className="action">
                  <div className="icon paraphrasing" />
                  패러프레이징
                </div>
              </div>
              <div className="suggestion-paragraphs">
                <label className="suggestion-item">
                  <input type="radio" name="suggestion-paragraphs" />
                  <span>유전자감식의 활용</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default TactoArticle
