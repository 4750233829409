import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { updatedDate } from '../util/time-formatter'
import RecentWorkspaceItems from '../domain/RecentWorkspaceItems'

type Props = {
  workspace: RecentWorkspaceItems
}

const RecentWorkspace = ({ workspace }: Props) => {
  const navigate = useNavigate()

  const moveToProjectDetail = useCallback(
    (id: number, query?: string) => {
      navigate(`/tacto/projects/${id}${query ? `?menu=${query}` : ''}`)
    },
    [navigate]
  )

  return (
    <>
      <div aria-label="recent-projects" className="recent-items-wrap">
        <div className="title">최근 프로젝트</div>
        <div className="recent-items">
          {workspace.projects.map((recentProject) => (
            <div
              className="recent-item"
              key={`project-${recentProject.id}`}
              onClick={() => moveToProjectDetail(recentProject.id)}
            >
              <div className="name">{recentProject.name}</div>
              <div className="updated-at">{updatedDate(recentProject.updatedAt)}</div>
            </div>
          ))}
        </div>
      </div>
      <div aria-label="recent-topics" className="recent-items-wrap">
        <div className="title">최근 토픽</div>
        <div className="recent-items">
          {workspace.topics.map((recentTopic, index) => (
            <div
              className="recent-item"
              key={`topic-${recentTopic.projectId}-${index}`}
              onClick={() => moveToProjectDetail(recentTopic.projectId)}
            >
              <div className="project-name">{recentTopic.projectName}</div>
              <div className="name">{recentTopic.name}</div>
              <div className="updated-at">{updatedDate(recentTopic.updatedAt)}</div>
            </div>
          ))}
        </div>
      </div>
      <div aria-label="recent-memos" className="recent-items-wrap">
        <div className="title">최근 메모</div>
        <div className="recent-items">
          {workspace.memos.map((memo, index) => (
            <div
              className="recent-item"
              key={`memo-${memo.projectId}-${index}`}
              onClick={() => moveToProjectDetail(memo.projectId, 'memos')}
            >
              <div className="project-name">{memo.projectName}</div>
              <div className="name">{memo.title}</div>
              <div className="updated-at">{updatedDate(memo.updatedAt)}</div>
            </div>
          ))}
        </div>
      </div>
      <div aria-label="recent-tacto-articles" className="recent-items-wrap">
        <div className="title">최근 아티클</div>
        <div className="recent-items">
          {workspace.tactoArticles.map((tactoArticle, index) => (
            <div
              className="recent-item"
              key={`article-${tactoArticle.id}-${index}`}
              onClick={() => {
                navigate(`/tacto/projects/${tactoArticle.projectId}/articles/${tactoArticle.id}`)
              }}
            >
              <div className="project-name">{tactoArticle.projectName}</div>
              <div className="name">{tactoArticle.title}</div>
              <div className={`badge ${tactoArticle.isCompleted ? 'completed' : ''}`}>
                <span>{tactoArticle.contentSize} 자</span>
                <span className="dot" />
                <span>{tactoArticle.isCompleted ? '완료' : '작성중'}</span>
              </div>
              <div className="updated-at">{updatedDate(tactoArticle.updatedAt)}</div>
            </div>
          ))}
        </div>
      </div>
      <div aria-label="recent-materials" className="recent-items-wrap">
        <div className="title">최근 자료</div>
        <div className="recent-items">
          {workspace.materials.map((material, index) => (
            <div
              className="recent-item"
              key={`material-${material.projectId}-${index}`}
              onClick={() => moveToProjectDetail(material.projectId, 'materials')}
            >
              <div className="project-name">{material.projectName}</div>
              <div className="name">{material.title}</div>
              <div className="attachment-memo-container">
                <div className={material.attachmentId ? 'badge existed' : 'badge'}>
                  <div className="icon attachment" />
                  첨부
                </div>
                <div className={material.memoId ? 'badge existed' : 'badge'}>
                  <div className="icon memo" />
                  메모
                </div>
              </div>
              <div className="updated-at">{updatedDate(material.updatedAt)}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default RecentWorkspace
