import React from 'react'
import { updatedDate } from '../util/time-formatter'
import WorkspaceTactoArticle from '../domain/WorkspaceTactoArticle'
import { useNavigate } from 'react-router-dom'

type Props = {
  tactoArticles: WorkspaceTactoArticle[]
}

const TactoArticleList = ({ tactoArticles }: Props) => {
  const navigate = useNavigate()
  return (
    <div className="workspace-items" aria-label="tacto-article-list">
      {tactoArticles.map((item, index) => (
        <div
          className="workspace-item"
          key={`${item.id}-${index}`}
          onClick={() => navigate(`/tacto/projects/${item.projectId}/articles/${item.id}`)}
        >
          <div className="project-name">{item.projectName}</div>
          <div className="name">{item.title}</div>
          <div className="description">{item.content}</div>
          <div className="progress-container">
            <div className={`progress-badge ${item.isCompleted ? 'completed' : ''}`}>
              <span>{item.contentSize} 자</span>
              <span className="dot" />
              <span>{item.isCompleted ? '완료' : '작성중'}</span>
            </div>
            <div className="updated-at">{updatedDate(item.updatedAt)}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default TactoArticleList
