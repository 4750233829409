import NiceSelect from '../../common/component/NiceSelect'
import { Fragment, useCallback, useEffect, useState } from 'react'

import CancelButton from '../../common/component/CancelButton'
import ConfirmButton from '../../common/component/ConfirmButton'

import { getAllRootNodes } from '../repository/TactoProjectRepository'
import { moveMaterialsToOtherTopic } from '../repository/MaterialsRepository'
import TopicTreeNode from '../domain/TopicTreeNode'

type Props = {
  rootNode: TopicTreeNode
  ids: Set<number>
  fetchMaterials: () => void
  closeModal: () => void
}

function drawTreeInMoveModal(
  treeNodeMap: { [key in string]: TopicTreeNode },
  selectedId: string,
  onSelectTopic: (id: string) => void
) {
  return Object.values(treeNodeMap).map((value) => {
    return (
      <Fragment key={value.id}>
        <div
          className={`tree-node-item children ${selectedId === value.id && 'on'}`}
          onClick={() => {
            onSelectTopic(value.id)
          }}
        >
          <li>{value.name}</li>
        </div>
        {value.children && (
          <ul className="children">
            {drawTreeInMoveModal(value.children, selectedId, onSelectTopic)}
          </ul>
        )}
      </Fragment>
    )
  })
}

const MoveTopicMaterial = ({ rootNode, ids, fetchMaterials, closeModal }: Props) => {
  const [topicTreeOptions, setTopicTressOptions] = useState<Map<string, string>>(new Map())
  const [topicRootNodes, setTopicRootNodes] = useState<TopicTreeNode[]>([])
  const [selectedTopicId, setSelectedTopicId] = useState<string>('')
  const [selectedRootNode, setSelectedTree] = useState<TopicTreeNode>()

  const onMoveHandler = useCallback(() => {
    moveMaterialsToOtherTopic(selectedTopicId, Array.from(ids))
      .then(fetchMaterials)
      .finally(closeModal)
  }, [selectedTopicId, ids, fetchMaterials, closeModal])

  useEffect(() => {
    getAllRootNodes().then((rootNodes) => {
      setTopicRootNodes(rootNodes)
      const currentRootNode = rootNodes.find((tree) => tree.id === rootNode.id)
      setSelectedTree(currentRootNode)
      setSelectedTopicId('')
      const options = new Map()
      rootNodes.map((tree) => options.set(`${tree.id}`, tree.name))
      setTopicTressOptions(options)
    })
  }, [rootNode])

  const onSelectCallback = useCallback(
    (key: string) => {
      const selectedRootNode = topicRootNodes.find((tree) => `${tree.id}` === key)
      setSelectedTree(selectedRootNode)
    },
    [topicRootNodes]
  )

  return (
    <div className="move-topic-material-modal">
      <div className="title">자료 이동</div>
      <div className="item">
        <div className="label">이동할 토픽트리</div>
        <NiceSelect
          ariaLabel="topic-tree"
          selectedValue={selectedRootNode?.name}
          options={topicTreeOptions}
          onSelectCallback={onSelectCallback}
        />
      </div>
      <div className="topic-tree" aria-label="topic-nodes">
        {selectedRootNode?.children &&
          drawTreeInMoveModal(selectedRootNode.children, selectedTopicId, setSelectedTopicId)}
      </div>
      <div className="buttons">
        <CancelButton name="취소" onClick={closeModal} />
        <ConfirmButton name="이동" onClick={onMoveHandler} />
      </div>
    </div>
  )
}

export default MoveTopicMaterial
