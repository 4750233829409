import NiceSelect from '../../common/component/NiceSelect'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { getAllRootNodes } from '../repository/TactoProjectRepository'
import TopicTreeNode from '../domain/TopicTreeNode'
import { RightSection } from './TopicTreeManagement'
import { v4 as uuidv4 } from 'uuid'

type Props = {
  onCreateTopicTrees: (topicTree: TopicTreeNode) => void
  setRightSection: (rightSection: RightSection) => void
}

function drawTreeInSection(
  treeNodeMap: { [key in string]: TopicTreeNode },
  onSelectTopic: (topicNode: TopicTreeNode) => void,
  selectedId?: string
) {
  return Object.values(treeNodeMap).map((value) => {
    return (
      <Fragment key={value.id}>
        <div
          className={`tree-node-item children ${selectedId === value.id && 'on'}`}
          onClick={() => {
            onSelectTopic(value)
          }}
        >
          <li>{value.name}</li>
        </div>
        {value.children && (
          <ul className="children">
            {drawTreeInSection(value.children, onSelectTopic, selectedId)}
          </ul>
        )}
      </Fragment>
    )
  })
}

function reissueNewIdsForChildren(
  parentId: string,
  children: { [id: string]: TopicTreeNode } | null
): { [id: string]: TopicTreeNode } | null {
  if (children === null) {
    return null
  }
  let result = {}
  for (const topicTreeNode of Object.values(children)) {
    const newId = uuidv4()
    result = {
      ...result,
      [newId]: {
        ...topicTreeNode,
        id: newId,
        parentId: parentId,
        children: reissueNewIdsForChildren(newId, topicTreeNode.children),
      },
    }
  }
  return result
}

function reissueNewIds(selectedTopic: TopicTreeNode) {
  const id = uuidv4()
  return {
    ...selectedTopic,
    id,
    parentId: id,
    children: reissueNewIdsForChildren(id, selectedTopic.children),
  }
}

const CreateTopicByExistedTopicSection = ({ onCreateTopicTrees, setRightSection }: Props) => {
  const [topicTreeOptions, setTopicTressOptions] = useState<Map<string, string>>(new Map())
  const [topicRootNodes, setTopicRootNodes] = useState<TopicTreeNode[]>([])
  const [selectedRootNode, setSelectedTree] = useState<TopicTreeNode>()
  const [selectedTopic, setSelectedTopic] = useState<TopicTreeNode>()

  useEffect(() => {
    getAllRootNodes().then((rootNodes) => {
      setTopicRootNodes(rootNodes)
      const options = new Map()
      rootNodes.map((tree) => options.set(`${tree.id}`, tree.name))
      setTopicTressOptions(options)
    })
  }, [])

  const onSelectCallback = useCallback(
    (key: string) => {
      const selectedRootNode = topicRootNodes.find((tree) => `${tree.id}` === key)
      setSelectedTree(selectedRootNode)
    },
    [topicRootNodes]
  )
  return (
    <div
      aria-label="creation-topic-based-existed-topic"
      className="creation-topic-based-existed-topic"
    >
      <div className="title">기존 토픽트리에서 불러오기</div>
      <NiceSelect
        ariaLabel="topic-tree"
        selectedValue={selectedRootNode?.name || '토픽트리를 선택해주세요'}
        options={topicTreeOptions}
        onSelectCallback={onSelectCallback}
      />
      <div className="topic-tree" aria-label="topic-nodes">
        {selectedRootNode?.children &&
          drawTreeInSection(selectedRootNode.children, setSelectedTopic, selectedTopic?.id)}
      </div>
      {selectedRootNode && (
        <div className="buttons">
          <button className="cancel" onClick={() => setRightSection('')}>
            취소
          </button>
          <button
            className="confirm"
            onClick={() => {
              if (!selectedTopic) {
                alert('선택한 토픽이 없습니다.')
                return
              }
              onCreateTopicTrees(reissueNewIds(selectedTopic))
            }}
          >
            적용
          </button>
        </div>
      )}
    </div>
  )
}

export default CreateTopicByExistedTopicSection
